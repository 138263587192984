<template>
  <v-app
    class="rs-report-wrap"
    :class="pdfType ? `is-pdf-${pdfType}` : ''"
    color="transparent"
  >
    <v-main>
      <slot />
    </v-main>
    <Footer
      :isDarkTheme="pdfType === 'print' ? false : true"
      :showDisclosure="false"
    />
    <div style="page-break-before: always"></div>
  </v-app>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  props: {
    navItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    Footer,
  },
  data() {
    return {
      triangleWidth: "",
      pdfType: null,
    };
  },
  created() {
    window.addEventListener("resize", () => {
      this.handleAdjustTriangleBg();
    });

    if (this.$route.query.pdfType) {
      this.pdfType = this.$route.query.pdfType;
    }
  },
  methods: {
    handleAdjustTriangleBg() {
      let triangleWidth;
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;
      let angle = Math.atan(winHeight / winWidth);

      triangleWidth = Math.sqrt(Math.pow(winWidth, 2) + Math.pow(winHeight, 2));
      this.triangleWidth = triangleWidth + "px";

      angle = (angle * 270) / Math.PI;

      //   console.log("Right Width", triangleWidth);
      //   console.log("ANGLE", "rotate(-" + angle + "deg)");
      if (this.$refs.test) {
        this.$refs.test.style.width = triangleWidth + "px";
        this.$refs.test.style.transform = "rotate(-" + angle + "deg)";
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/report";
</style>
